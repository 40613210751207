import React from 'react';

export default function Navbar(props) {
    return (
        <div className='navbar'>
            <a href='#'><img className='logo' src={process.env.PUBLIC_URL + '/type.png'} /></a>
            <ul>
            <li><a className='navlink' href='#'> FreeCourse </a></li>
            <li><a className='navlink' href='#'> Games </a></li>
            <li><a className='navlink' href='#'> Simulation </a></li>
            <li><a className='navlink' href='#'> SixthSense </a></li>
            </ul>
        </div>
    )
}