import React, { useState, useEffect } from 'react';
import './App.css';
import CourseViewer from './CourseViewer';
import _ from 'lodash';

const sections = ['start', 'showcase', 'reviews', 'schedule'];
const minWait = 850;

export default function Home(props) {
    // Define the state variables
    const [position, setPosition] = useState({});
    const [nowScrolling, setNowScrolling] = useState(false);

    // Grab initial elements on first render
    useEffect(() => {
        const initialPosition = {
            previous: {
                name: null,
                section: null,
                content: null
            },
            current: {
                name: 'start',
                section: document.querySelector('#start'),
                content: document.querySelector('#start-content')
            },
            next: {
                name: 'showcase',
                section: document.querySelector('#showcase'),
                content: document.querySelector('#showcase-content')
            }
        }
        setPosition(() => { return initialPosition });
    }, []);

    // Handle the scroll animation
    const handleWheel = (event) => {

        // Make sure multiple scrolls don't fire it up
        if (nowScrolling) {return;}
        setNowScrolling(true);

        // If the scroll is down and it's not the last section
        if (event.deltaY > 0 && position.current.name != sections[sections.length - 1]) {

            // 1. Fade out all elements of the current section
            position.current.content.classList.add('disappearUp');
            
            // 2. Scroll the next section into view
            setTimeout(() => {
                position.next.section.scrollIntoView({block: 'start'});
                // Clear the fade-out for later reuse
                position.current.content.classList.remove('disappearUp');
            }, minWait);

            // 3. Fade in the next section
            position.next.content.classList.add('appearUp');
            // Clear the fade-in for later reuse
            setTimeout(() => {
                position.next.content.classList.remove('appearUp');
            }, minWait * 1.8);

            // 4. Update the position state
            const newNext = sections[sections.indexOf(position.next.name)+1];
            setPosition((former) => {

                return {
                previous: {
                    name: former.current.name,
                    section: former.current.section,
                    content: former.current.content
                },
                current: {
                    name: former.next.name,
                    section: former.next.section,
                    content: former.next.content
                },
                next: {
                    name: newNext,
                    section: document.querySelector(`#${newNext}`),
                    content: document.querySelector(`#${newNext}-content`)
                }
            }});

            setTimeout(() => {
                console.log(position);
                setNowScrolling(false);
              }, minWait * 2.0);
            return;

        // If the scroll is up and it's not the first section
        } else if (event.deltaY < 0 && position.current.name != sections[0]) {

            // 1. Fade out all elements of the current section
            position.current.content.classList.add('disappearDown');

            // 2. Scroll the previous section into view
            setTimeout(() => {
                position.previous.section.scrollIntoView({block: 'start'});
                // Clear the fade-out for later reuse
                position.current.content.classList.remove('disappearDown');
            }, minWait);

                // 3. Fade in the previous section content
                position.previous.content.classList.add('appearDown');
                // Clear the fade-in for later reuse
                setTimeout(() => {
                    position.previous.content.classList.remove('appearDown');
                }, minWait * 1.8);

            // 4. Update the position state
            const newPrevious = sections[sections.indexOf(position.previous.name)-1];
            setPosition((former) => {
                return {
                previous: {
                    name: newPrevious,
                    section: document.querySelector(`#${newPrevious}`),
                    content: document.querySelector(`#${newPrevious}-content`)
                },
                current: {
                    name: former.previous.name,
                    section: former.previous.section,
                    content: former.previous.content
                },
                next: {
                    name: former.current.name,
                    section: former.current.section,
                    content: former.current.content
                }
            }});

            setTimeout(() => {
                console.log(position);
                setNowScrolling(false);
              }, minWait * 2.0);
            return;
        } else if (event.deltaY > 0 && position.current.name == sections[sections.length - 1]) {
            setNowScrolling(false);
            return;
        } else if (event.deltaY < 0 && position.current.name == sections[0]) {
            setNowScrolling(false);
            return;
        }
    }
    return (
    <div id='home'>
        <section id='start' onWheel={handleWheel}> 
            <div id='start-content'>
                <div className='title-qr'>
                    <h1 className='title'>We Teach <br /> English Online. </h1>
                    <div className='qr-holder'>
                        <img className='qr whatsapp' src='whatsapp.jpeg' />
                        <img className='qr wechat' src='wechat.jpeg' />
                    </div>
                </div>
                <img className={`graphic ${props.graphic}`}  src={`gif/${props.graphic}.gif`} /> 
            </div>
        </section>
        
        <section id='showcase' onWheel={handleWheel}>
            <div id='showcase-content'>
                <div className='graphic-wrapper'>
                    <img src='gif/blackBallCube.gif' /> 
                    <h1 className='title'> Our Pricing is <span className='transparentText'>Transparent.</span> </h1>
                </div>
                <div className='info-wrapper'>
                    <CourseViewer />
                    <div className='title-wrapper'>
                       
                    </div>
                </div>
            </div>
        </section>

        <section id='reviews' onWheel={handleWheel}>
            <div id='reviews-content'>
                <img src='gif/sphere.gif' />
            </div>
        </section>

        <section id='schedule' onWheel={handleWheel}>
            <div id='schedule-content'>
                <img src='gif/blackPearl.gif' style={{width: '160%', position: 'relative', right: '40%' }}/>
                <h1 className='title'> This Is <br /> The Last One </h1>
            </div>
        </section>
    </div>
    );
}

Home.defaultProps = {
    'graphic': 'stairs'
}

