import './App.css';
import Navbar from './Navbar';
import Home from './Home';

function App() {

  const quotes = [
    <h1 className='title'>Six Levels, <br /> One Destination.</h1>,
    <h1 className='title'>Take It <br /> One Step At A Time.</h1>,
    <h1 className='title'>Your Teleport<br /> Towards Fluency.</h1>,
    <h1 className='title'>Your Language<br /> Superpower.</h1>,
    <h1 className='title'>Take It <br /> Step By Step.</h1>,
    <h1 className='title'>Дорогу Осилит <br /> Идущий.</h1>,
    <h1 className='title'>六个层次, <br /> 一个目的地。</h1>,
    <h1 className='title'>We Teach <br /> English Online.</h1>,
  ];

  // Choose a random graphic on page load
  const graphics = ['abstract', 'glitter'];
  function randomChoice(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }
  
  return (
    <div className="App">
      <Navbar />
      <Home graphic={randomChoice(graphics)}/>
    </div>
  );
}

export default App;
