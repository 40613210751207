import React, { useState } from 'react';

const english = (<>
    <div className='window courseInfo'>
                <h1>ENGLISH</h1>
                <div className='textBundle'>
                    <p>Up one level in 6 months</p>
                    <p>Extensive vocabulary</p>
                    <p>Grammar made easy</p>
                    <p>Read, Listen, Speak, Write</p>
                 </div>
            </div>

            <div pricing-wrapper>
                <div className='window priceInfo' id='groupPrice'>
                    <h1>GROUP</h1>
                    <div className='textBundle'>
                        <p>3 hours a week</p>
                        <p>6-people groups</p>
                        <p>Includes Pair Practice</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥1000/month</h2>
                    </div>
                </div>

                <div className='window priceInfo' id='one-to-onePrice'>
                    <h1>ONE TO ONE</h1>
                    <div className='textBundle'>
                        <p>Your own schedule</p>
                        <p>Fully adapted to your needs</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥200/hr or</h2>
                        <h2>¥2000/month</h2>
                    </div>
                </div>
            </div>
</>)

const ieltsWriting = (<>
    <div className='window courseInfo'>
                <h1>IELTS WRITING</h1>
                <div className='textBundle'>
                    <p>24 Essays with Full Feedback</p>
                    <p>Band 8.0 Strategies</p>
                    <p>Powerful Grammar and Vocab</p>
                    <p>Be Ready in 6 Weeks!</p>
                 </div>
            </div>

            <div pricing-wrapper>
                <div className='window priceInfo' id='groupPrice'>
                    <h1>GROUP</h1>
                    <div className='textBundle'>
                        <p>6-people groups</p>
                        <p>1.5 hour lessons</p>
                        <p>2 times a week</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥2000 / 6 weeks</h2>
                    </div>
                </div>

                <div className='window priceInfo' id='one-to-onePrice'>
                    <h1>ONE TO ONE</h1>
                    <div className='textBundle'>
                        <p>Your own schedule</p>
                        <p>Fully adapted to your needs</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥300/hr or</h2>
                        <h2>¥5000 / 6 weeks</h2>
                    </div>
                </div>
            </div>
</>)

const ieltsSpeaking = (<>
    <div className='window courseInfo'>
                <h1 id='ieltsSpeakingH1'>IELTS SPEAKING</h1>
                <div className='textBundle'>
                    <p>Regular tests with scores</p>
                    <p>Band 8.0 Strategies</p>
                    <p>Lots of Real Practice</p>
                    <p>Be Ready in 6 Weeks!</p>
                    
                 </div>
            </div>

            <div pricing-wrapper>
                <div className='window priceInfo' id='groupPrice'>
                    <h1>GROUP</h1>
                    <div className='textBundle'>
                        <p>6-people groups</p>
                        <p>1.5 hour lessons</p>
                        <p>2 times a week</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥2000 / 6 weeks</h2>
                    </div>
                </div>

                <div className='window priceInfo' id='one-to-onePrice'>
                    <h1>ONE TO ONE</h1>
                    <div className='textBundle'>
                        <p>Your own schedule</p>
                        <p>Fully adapted to your needs</p>
                    </div>
                    <div className='priceBundle'>
                        <h2>¥300/hr or</h2>
                        <h2>¥5000 / 6 weeks</h2>
                    </div>
                </div>
            </div>
</>)

const courses = {
    'english': english, 
    'ieltsWriting': ieltsWriting,
    'ieltsSpeaking': ieltsSpeaking,
};

export default function CourseViewer() {
    const [displayedCourse, setDisplayedCourse] = useState('english')

    const handleCourseClick = (event) => {
        setDisplayedCourse(event.target.id);
        return;
    }

    return (
        <div className='courseViewer'>

            <div className='qr-holder'>
                <img className='qr whatsapp' src='whatsapp.jpeg' />
                <img className='qr wechat' src='wechat.jpeg' />
            </div>

            {courses[displayedCourse]}

            <div className='courseNav'>
                <ul>
                    <li id='english' className='active' onClick={handleCourseClick}>English</li>
                    <li id='ieltsWriting' onClick={handleCourseClick}>IELTS Writing</li>
                    <li id='ieltsSpeaking' onClick={handleCourseClick}>IELTS Speaking</li>
                </ul>
            </div>
        </div>
    );
}